export const Keyboard = {
	Space: ' ',
	Escape: 'Escape',
	Enter: 'Enter',
	Tab: 'Tab',
	ArrowLeft: 'ArrowLeft',
	ArrowRight: 'ArrowRight',
	ArrowUp: 'ArrowUp',
	ArrowDown: 'ArrowDown',
}
