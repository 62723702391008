import type { Stored } from '../types'

export function isSessionStorageAvailable(): boolean {
	const windowExists = typeof window !== 'undefined'

	return windowExists && !!window.sessionStorage
}

export const getFromSessionStorage = <T>(keyName: string): Stored<T> | null => {
	if (!isSessionStorageAvailable()) {
		return null
	}

	try {
		const storedValue: string = window.sessionStorage.getItem(keyName) || ''

		return JSON.parse(storedValue) as Stored<T>
	} catch (error) {
		return null
	}
}

/**
 * @returns {boolean} "true" if it was able to save it to Local Storage, otherwise it's return is "false"
 */
export const setToSessionStorage = (
	keyName: string,
	value: unknown
): boolean => {
	if (!isSessionStorageAvailable()) {
		return false
	}

	try {
		const storableValue = JSON.stringify(value)
		window.sessionStorage.setItem(keyName, storableValue)

		return true
	} catch (error) {
		return false
	}
}

export const removeFromSessionStorage = (keyName: string): boolean => {
	if (!isSessionStorageAvailable()) {
		return false
	}

	try {
		window.sessionStorage.removeItem(keyName)

		return true
	} catch (error) {
		return false
	}
}
