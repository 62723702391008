'use client'

import { NotificationsContext } from 'notifications/context/NotificationsContext'
import { useContext } from 'react'

export const useNotificationsContext = () => {
	const notificationsContext = useContext(NotificationsContext)

	if (!notificationsContext) {
		throw new Error(
			'useNotificationsContext must be used within a NotificationsProvider'
		)
	}

	return notificationsContext
}
