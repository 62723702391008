'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { ChatbotGenericEvents } from 'chatbot/utils/analytics/constants'

export const useChatbotAnalytics = () => {
	const sendOpenChatbotAnalytic = () => {
		sendGenericEvent(ChatbotGenericEvents.OPEN_CHATBOT)
	}

	return {
		sendOpenChatbotAnalytic,
	}
}
