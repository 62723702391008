import { IconDone } from 'icons/components/IconDone'
import { usePathname, useSearchParams } from 'next/navigation'
import type { SnackbarProps } from 'notifications/components/Snackbar/Snackbar'
import {
	type Dispatch,
	type SetStateAction,
	useCallback,
	useEffect,
} from 'react'
import {
	getFromSessionStorage,
	removeFromSessionStorage,
	setToSessionStorage,
} from 'utils/webStorage/sessionStorage'

const SESSION_STORAGE_NOTIFICATION_KEY = 'notification-on-nav'

export enum SNACKBAR_ICON {
	DONE = 'done',
}

const ICON_OPTIONS: Record<SNACKBAR_ICON, SnackbarProps['icon']> = {
	[SNACKBAR_ICON.DONE]: <IconDone color='content-inverse' />,
}

export type NavigationSnackbarProps = Omit<
	SnackbarProps,
	'onClick' | 'onClose' | 'icon'
> & { iconName?: SNACKBAR_ICON }

export const useNotificationsStorage = (
	setActualNotification: Dispatch<SetStateAction<SnackbarProps[]>>
) => {
	const pathname = usePathname()
	const searchParams = useSearchParams()

	const addNotificationOnNavigation = useCallback(
		(item: NavigationSnackbarProps) => {
			setToSessionStorage(SESSION_STORAGE_NOTIFICATION_KEY, item)
		},
		[]
	)

	useEffect(() => {
		const notification = getFromSessionStorage<NavigationSnackbarProps>(
			SESSION_STORAGE_NOTIFICATION_KEY
		)
		if (notification) {
			const newNotification = {
				...notification,
				icon: notification.iconName
					? ICON_OPTIONS[notification.iconName]
					: undefined,
			}

			setActualNotification((prevQueue) => [...prevQueue, newNotification])
			removeFromSessionStorage(SESSION_STORAGE_NOTIFICATION_KEY)
		}
	}, [pathname, searchParams])

	return { addNotificationOnNavigation }
}
